import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import abimbola from '../../assets/images/departments/radio/abimbola.jpg';
import abosede from '../../assets/images/departments/radio/abosede.jpg';
import adekunle from '../../assets/images/departments/radio/adekunle.png';
import adenuga from '../../assets/images/departments/radio/adenuga.jpg';
import adeyemo from '../../assets/images/departments/radio/adeyemo.jpg';
import afoke from '../../assets/images/departments/radio/afoke.jpg';
import ahaneku from '../../assets/images/departments/radio/ahaneku.jpg';
import aloyah from '../../assets/images/departments/radio/aloyah.jpg';
import caleb from '../../assets/images/departments/radio/caleb.jpg';
import edet from '../../assets/images/departments/radio/edet.jpg';
import erhie from '../../assets/images/departments/radio/erhie.jpg';
import faustina from '../../assets/images/departments/radio/faustina.jpg';
import gabriel from '../../assets/images/departments/radio/gabriel.jpg';
import ibrahim from '../../assets/images/departments/radio/ibrahim.jpg';
import ikpesu from '../../assets/images/departments/radio/ikpesu.jpg';
import iruhw from '../../assets/images/departments/radio/iruhw.jpg';
import john from '../../assets/images/departments/radio/john.jpg';
import kalejaiye from '../../assets/images/departments/radio/kalejaiye.jpg';
import mojeed from '../../assets/images/departments/radio/mojeed.jpg';
import lawani from '../../assets/images/departments/radio/lawani.jpg';
import nnubia from '../../assets/images/departments/radio/nnubia.jpg';
import obodo from '../../assets/images/departments/radio/obodo.jpg';
import obumueke from '../../assets/images/departments/radio/obumueke.jpg';
import odunayo from '../../assets/images/departments/radio/odunayo.jpg';
import ogbere from '../../assets/images/departments/radio/ogbere.jpg';
import okonkwo from '../../assets/images/departments/radio/okonkwo.jpg';
import olajumoke from '../../assets/images/departments/radio/olajumoke.jpg';
import oraka from '../../assets/images/departments/radio/oraka.jpg';
import olalekan from '../../assets/images/departments/radio/olalekan.jpg';
import olaleye from '../../assets/images/departments/radio/olaleye.jpg';
import olubukola from '../../assets/images/departments/radio/olubukola.jpg';
import oluwaseyi from '../../assets/images/departments/radio/oluwaseyi.jpg';
import omobola from '../../assets/images/departments/radio/omobola.jpg';
import omodele from '../../assets/images/departments/radio/omodele.jpg';
import omolara from '../../assets/images/departments/radio/omolara.jpg';
import omotooke from '../../assets/images/departments/radio/omotooke.jpg';
import orah from '../../assets/images/departments/radio/orah.jpg';
import osaro from '../../assets/images/departments/radio/osaro.jpg';
import osungbesan from '../../assets/images/departments/radio/osungbesan.jpg';
import rasheed from '../../assets/images/departments/radio/rasheed.jpg';
import soyebi from '../../assets/images/departments/radio/soyebi.jpg';
import towuromola from '../../assets/images/departments/radio/towuromola.jpg';
import uduakabasi from '../../assets/images/departments/radio/uduakabasi.jpg';

const Radio = () => {
  const services = [
    {
      name: 'Radiodiagnostic services to patients (Paedistric Imaging)',
      value:
        'Ultrasonography: Transfontanelle Ultrasonography, Transcranial Doppler studies in Sickle Cell Disease, Chest, Abdomino-pelvic, Genitourinary system, Musculoskeletal, joints, Cardiac (Echocardiography), Ocular studies, Thyroid, scrotal',
    },
    {
      name: '',
      value:
        'Conventional Radiography (with or without contrast agents)  Chest, Abdomino-pelvic, Genitourinary system, Musculoskeletal, joints, Intravenous Urography, Cysto-urethrography (Retrograde and micturating) Gastrointestinal studies',
    },
    {
      name: '',
      value:
        'Computed tomography of all parts of the body including central nervous system and Spinal cord',
    },
    {
      name: '',
      value:
        'Magnetic Resonance Imaging of all parts of the body  including central nervous system and Spinal cord',
    },
    {
      name: 'Radiodiagnostic services to patients (Women Imaging)',
      value:
        'Ultrasonography: Chest, Abdomino-pelvic, Genitourinary system, Musculoskeletal, joints, Cardiac (Echocardiography), Ocular studies, Thyroid, Breast, Obstetric and Gynaecologic Ultrasonography',
    },
    {
      name: '',
      value:
        'Conventional Radiography (with or without contrast agents)  Chest, Abdomino-pelvic, Genitourinary system, Musculoskeletal, joints, Intravenous Urography, Cysto-urethrography (Retrograde and micturating) Gastrointestinal studies',
    },
    {
      name: '',
      value: 'Mammography',
    },
    {
      name: '',
      value: 'Computed tomography of all parts of the body',
    },
    {
      name: '',
      value: 'Magnetic Resonance Imaging of all parts of the body',
    },
    {
      name: '',
      value: 'Densitometry',
    },
    {
      name:
        'Radiodiagnostic services to patients (Imaging of the Adult Chest (Male and Female))',
      value: 'Conventional Radiography',
    },
    {
      name: '',
      value: 'Ultrasonography',
    },
    {
      name: '',
      value: 'Computed Tomography',
    },
    {
      name: '',
      value: 'Magnetic Resonance Imaging',
    },
    {
      name:
        'Radiodiagnostic services to patients (Imaging of the Central Nervous System)',
      value: 'Conventional Radiography',
    },
    {
      name: '',
      value: 'Computed Tomography',
    },
    {
      name: '',
      value: 'Computed Tomography Angiography',
    },
    {
      name: '',
      value: 'Magnetic Resonance Imaging',
    },
    {
      name: '',
      value: 'Magnetic Resonance Angiography',
    },
    {
      name:
        'Radiodiagnostic services to patients (Imaging of the Muscular Skeletal System)',
      value: 'Joint space Ultrasonography',
    },
    {
      name: '',
      value: 'Conventional Radiography',
    },
    {
      name: '',
      value: 'Arthrography',
    },
    {
      name: '',
      value: 'Computed Tomography',
    },
    {
      name: '',
      value: 'Computed Tomography Angiography',
    },
    {
      name: '',
      value: 'Magnetic Resonance Imaging',
    },
    {
      name: '',
      value: 'Magnetic Resonance Angiography',
    },
    {
      name:
        'Radiodiagnostic services to patients (Peripheral Vascular Doppler Studies)',
      value: 'Arterial Doppler',
    },
    {
      name: '',
      value: 'Venous Doppler',
    },
    {
      name:
        'Radiodiagnostic services to patients (Nonvascular Interventional Radiology)',
      value: 'Ultrasound Guided Biopsy or Fluid drainages',
    },
    {
      name: '',
      value: 'Computed Tomography Guided Biopsy or Fluid drainages',
    },
    {
      name: 'Training of Resident Doctors',
      value: 'General Radiology in the first 3years',
    },
    {
      name: '',
      value: 'Subspecialty training in final three years',
    },
    {
      name: '',
      value:
        'Supervision of Research activities and write up towards Fellowship Examinations',
    },
    {
      name: 'Research activities and Grant Sourcing',
      value: 'To contribute to hospital and community service improvement',
    },
  ];

  const rows = services.map((element, ind) => (
    <tr key={ind}>
      <td>{element.name}</td>
      <td>{element.value}</td>
    </tr>
  ));

  return (
    <Layout pageTitle='Department of Radiodiagnosis | LUTH'>
      <NavOne />
      <PageHeader title='Department of Radiodiagnosis' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Radiodiagnosis
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Services'>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Subspecialties and Services Rendered</th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </Table>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={soyebi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Kofo O. Soyebi</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Head of Department & Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, FMCR, FWACS
                            <br />
                            <span className='detail-head'>
                              Speciality:
                            </span>{' '}
                            Women and Paediatric imaging
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={iruhw} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Nicholas Kayode Irurhe</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Professor & Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS (Lagos), MSc, Ph.D. FMCR, FICS, MNIM
                            <br />
                            <span className='detail-head'>
                              Speciality:
                            </span>{' '}
                            Chest and Women Imaging
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adekunle} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Adekunle A. Adeyomoye</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Associate Professor and Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FMCR, FWACS
                            <br />
                            <span className='detail-head'>
                              Speciality:
                            </span>{' '}
                            Abdominal and Neuroradiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omodele} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Omodele A. Olowoyeye</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Residency Training Coordinator, Associate Professor
                            and Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, RVT, MSc, MD, PhD, FMCR
                            <br />
                            <span className='detail-head'>
                              Speciality:
                            </span>{' '}
                            Vascular, Nephrourological & Musculoskeletal
                            Radiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olubukola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Olubukola A. Omidiji</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MPH, FMCR
                            <br />
                            <span className='detail-head'>
                              Speciality:
                            </span>{' '}
                            Paediatric and Women Imaging
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={caleb} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Caleb Itopa Bashiru YAKUBU</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Consultant, specialist grade (2)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            D.I.R, MBBS, FMCR
                            <br />
                            <span className='detail-head'>
                              Speciality:
                            </span>{' '}
                            Women and Chest imaging
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Residents
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={afoke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Afoke A. Oluwaseun</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Resident and Chief Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omotooke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Adebayo Omotooke Lara</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osaro} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Odiase Peter Osaro</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olajumoke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Arebi Monsurat Olajumoke</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={towuromola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Ajayi Bisoye Towuromola</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={uduakabasi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Ita Uduakabasi</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={faustina} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Okeke Ogonna Faustina</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Bsc, MBChB, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={obumueke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Okoye Chinedu Obumneke</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ahaneku} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Ahaneku Happiness. C</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={abimbola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Fadeyi Abimbola</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={gabriel} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Babatunde Gabriel</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS (Lagos), MBA (Covenant), MWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adenuga} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Adenuga Omogbolahan</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={john} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Aluchuru Chima John</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adeyemo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Adeyemo Damilola Esther</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={nnubia} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Nnubia, Josephat Onyebuchi</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={erhie} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Erhie OgheneKevwe Enoh</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={obodo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Obodo Chinazor Doris</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={orah} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Orah Faith Tobechukwu</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={aloyah} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>ALOYAH David M</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={odunayo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Idowu Ismail Odunayo</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={lawani} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Lawani Benjamin</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Radiographers
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olaleye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr Olaleye A. S.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Deputy Director, Radiography
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            DIR, BSc., PGD
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osungbesan} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Osungbesan O.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Deputy Director, Radiography
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            DIR, PGC, PGD, MSc
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={kalejaiye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs Kalejaiye H.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Asistant Director, Radiography
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            DIR, BSc., PGC
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olalekan} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Olalekan L.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Asistant Director, Radiography
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            DIR, PGC
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ibrahim} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs Ibrahim K.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Radiographer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            DIR, PGC
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={okonkwo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs Okonkwo N.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Radiographer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BSc., PGC
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Nurses
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omolara} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Alabi Comfort Omolara</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Deputy Director, Radiography
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            DIR, BSc., PGD
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olaleye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr Olaleye A. S.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Assistant Director of Nursing Services (ADNS)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            RN RM BNsc
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omobola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Ige Omobola O</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Nursing Officer (CNO)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            RN RM BNsc
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Dark Room Technicians
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oraka} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Oraka Olajumoke Omolabake</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Health Assistant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ikpesu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Ikpesu Johnson Avwerosuoghene</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Health Assistant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={abosede} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Bakare Oluwatoyin Abosede</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Health Assistant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oluwaseyi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Bakare Rhoda Oluwaseyi </h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Health Assistant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={mojeed} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Olowu Adesola Mojeed</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Principal Health Assistant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={edet} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Edet Ani Isaac</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Principal Health Assistant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={rasheed} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Ogunbanwo Olalekan Rasheed</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Senior Medical Darkroom Technician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E/Technician Certificate in film processing
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Non Academic Staff
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogbere} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Ogbere Titilayo</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Chief Confidential Secretary
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND (Secretarial Administration)
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Radio;
